import { __assign, __awaiter, __generator } from "tslib";
import toWebM from './utils/toWebM';
import parseWebP from './utils/parseWebP';
import parseRIFF from './utils/parseRIFF2';
import { autoAtob } from './utils/adaptor';
import { imageSrcToWebpDataUrl } from './utils/imageSrcToWebpDataUrl';
var defaultFps = 1;
export default {
    fromImageArray: function (images, fps, outputAsArray) {
        var curOutputAsArray = typeof Blob !== 'undefined' ? outputAsArray : true;
        var curFps = fps || defaultFps;
        return toWebM(images.map(function (image, index) {
            try {
                var webp = parseWebP(parseRIFF(autoAtob(image.slice(23))));
                var webpFrame = __assign(__assign({}, webp), { duration: 1000 / curFps });
                return webpFrame;
            }
            catch (error) {
                console.error("Before toWebM Error, Image Index ".concat(index));
                throw error;
            }
        }), curOutputAsArray);
    },
    fromImageArrayWithOptions: function (images, options) {
        if (options === void 0) { options = {}; }
        var fps = options.fps, duration = options.duration, outputAsArray = options.outputAsArray;
        var curFps = fps || defaultFps;
        if (duration) {
            curFps = 1000 / ((duration * 1000) / images.length);
        }
        return this.fromImageArray(images, curFps, outputAsArray);
    },
    fixImageDataList: function (images, options) {
        return __awaiter(this, void 0, void 0, function () {
            var result, _i, images_1, item, temp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = [];
                        _i = 0, images_1 = images;
                        _a.label = 1;
                    case 1:
                        if (!(_i < images_1.length)) return [3 /*break*/, 4];
                        item = images_1[_i];
                        return [4 /*yield*/, imageSrcToWebpDataUrl(item, options)];
                    case 2:
                        temp = _a.sent();
                        result.push(temp);
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, result];
                }
            });
        });
    }
};
