export default function (clusterTimecode) {
    var cuePoint = {
        id: 0xbb,
        data: [{
                data: Math.round(clusterTimecode),
                id: 0xb3, // CueTime
            },
            {
                id: 0xb7,
                data: [{
                        data: 1,
                        id: 0xf7, // CueTrack
                    },
                    {
                        data: 0,
                        size: 8,
                        id: 0xf1, // CueClusterPosition
                    },
                ],
            },
        ],
    };
    return cuePoint;
}
