export var imageSrcToWebpDataUrl = function (src, options) {
    return new Promise(function (resolve, reject) {
        var img = new Image();
        img.src = src;
        img.onload = function () {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.width = (options === null || options === void 0 ? void 0 : options.width) || img.width;
            canvas.height = (options === null || options === void 0 ? void 0 : options.height) || img.height;
            ctx.fillStyle = (options === null || options === void 0 ? void 0 : options.backgroundColor) || '#000';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
            var webp = canvas.toDataURL("image/webp");
            resolve(webp);
        };
        img.onerror = function (error) {
            reject(error);
        };
    });
};
